import { Controller } from "stimulus"
import Aos from "aos"
import "aos/dist/aos.css"

export default class extends Controller {
  connect() {
    this.animation = Aos.init({
      useClassNames: true,
      initClassName: false, 
      animatedClassName: "flipped" 
    });
  }
}