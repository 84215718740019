import { Controller } from "stimulus"

import tinymce from 'tinymce/tinymce'

// Here we place tinymce skins in the proper lookup location so it can find them
require.context(
  '!file-loader?name=js/[path][name].[ext]&context=node_modules/tinymce!tinymce/skins',
  true,
  /.*/
)

import 'tinymce/icons/default'
import 'tinymce/themes/silver'


import 'tinymce/plugins/link'
import 'tinymce/plugins/lists'

export default class extends Controller {
  connect() {
    tinymce.init({
      target: this.element,
      plugins: 'link lists',
      toolbar: "undo redo | styleselect | bold italic underline strikethrough | subscript superscript | alignleft aligncenter alignright alignjustify | bullist numlist | indent outdent | link ",
      convert_urls: false,
    }).then((editors) => { this.tinymce = editors[0] })
  }

  disconnect() {
    this.tinymce.destroy()
    // After destroy the textarea is still left at `display: none`, we will reset it here
    this.element.style.display = ''
  }
}