import { Controller } from "stimulus"
import "slick-carousel"
export default class extends Controller {
  connect() {
    $(this.element).slick({
      infinite: true,
      speed: 300,
      slidesToShow: 4,
      slidesToScroll: 4,
      responsive: [
        {
          breakpoint: 768, // BOOTSTRAP MD BREAKPOINT
          settings: {
            rows:2,
            slidesToShow: 3,
            slidesToScroll: 3
          }
        }
      ]
    });
  }
}