import { Controller } from "stimulus";
import "slick-carousel"
import Aos from "aos"

export default class extends Controller {
  static targets = [ "alternate", "overlay", "toggler", "individual", "business"]

  connect() {
    this.individualTarget.classList.add("animate__animated", "animate__slow");
    this.businessTarget.classList.add("animate__animated", "animate__slow");
  }

  // on click function that handles transitions
  rotateStyle () {
    this.toggleStyles()
    this.toggleTextContent()
    this.togglePageContent()
    this.forceSlickLoad()
    this.forceAosLoad()
  }

  toggleStyles(){
    this.alternateTargets.forEach((el) => {
      el.classList.add("alternate")
      // green-inset indicates we are on the business view
      if(el.classList.contains("green-inset")){
        el.classList.remove("green-inset")
        el.classList.add("blue-inset")
        el.querySelector(".btn-primary")?.classList.replace("btn-primary", "btn-secondary")
        el.querySelector(".btn-outline-primary")?.classList.replace("btn-outline-primary", "btn-outline-secondary")
      // blue-inset indicates we are on the personal view
      } else {
        el.classList.add("green-inset")
        el.classList.remove("blue-inset")
        el.querySelector(".btn-secondary")?.classList.replace("btn-secondary", "btn-primary")
        el.querySelector(".btn-outline-secondary")?.classList.replace("btn-outline-secondary", "btn-outline-primary")
      }
    })
  }

  toggleTextContent() {
    this.togglerTarget.innerHTML = this.togglerTarget.innerHTML == "B.io for Individuals" ? "B.io for Business" : "B.io for Individuals"
  }

  togglePageContent(){
    if(this.businessTarget.style.display == "none"){
      this.businessTarget.style.display = ""
      this.individualTarget.style.display = "none"
    } else {
      this.businessTarget.style.display = "none"
      this.individualTarget.style.display = ""
    }
  }

  forceSlickLoad(){
    // slick doesn't play nicely with display none so I here I force slick to check the
    // position of the carousel fixing the issue of it not displaying. Also this has
    // no plain JS alternative, it must be ran in jQuery.
    $("[data-controller='carousel']").slick('setPosition')
  }

  forceAosLoad(){
    Aos.init({
      useClassNames: true,
      initClassName: false,
      animatedClassName: "flipped"
    });
  }
}
