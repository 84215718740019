import { Controller } from "stimulus"
import "slick-carousel"
export default class extends Controller {
  connect() {
    const navbarToggler = document.querySelector(".navbar-toggler")
    let menuOpen = false
    navbarToggler.addEventListener("click", () => {
      if(!menuOpen){
        navbarToggler.classList.add("navbar-mobile-open")
        menuOpen = true
      }
      else{
        navbarToggler.classList.remove("navbar-mobile-open")
        menuOpen = false
      }
    });
  }
}